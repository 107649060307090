import React from "react";
import IndividualRegistrationInstructions from "../../auth/register/individual/IndividualRegistrationInstructions";
import SchoolRegistrationInstructions from "../../auth/register/school/SchoolRegistrationInstructions";

const DashboardInstructions = ({ placement }) => {
  return (
    <div className="dashboard-instructions">
      <div className="qr-banner">
        <div className="row">
          <div className="col-sm-12 col-md-6 left-data">
            <h3>
              Practice Maths, Science and English. Unlock the Potential of
              Practido's Mobile App!
            </h3>
            <p>
              <span className="bold">Topic-Based Practice Tests:</span> Sharpen
              your skills with targeted practice tests.
            </p>
            <ul className="bullet-list-data">
              <li>
                <span className="bold">Timed Tests:</span> Improve speed and
                accuracy under exam conditions.
              </li>
              <li>
                <span className="bold">Instant Results:</span> Get immediate
                feedback and insights into strengths and areas for improvement.
              </li>
              <li>
                <span className="bold">Test History:</span> Track progress over
                time with comprehensive test history and performance trends.
              </li>
              <li>
                <span className="bold">My World:</span> Earn points, coins, and
                trophies, compete for a spot in the Hall of Fame.
              </li>
              <li>
                <span className="bold">Leaderboard:</span> Engage in friendly
                competition and track your progress among top performers.
              </li>
            </ul>
            <div className="qr-scan">
              <img
                className="scan-img"
                src="/img/dash-scan.png"
                alt="scanner"
              />
              <div className="download">
                <p>
                  Download the app by scanning QR code. <span>OR</span>
                </p>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.naskay.olympiadpractice"
                  type="button"
                  className="btn btn-link"
                  rel="noreferrer"
                >
                  <img
                    src="/img/new-home/btn-google-play-black.png"
                    alt="google-play-button"
                  />
                </a>
              </div>
            </div>
          </div>
          {/* col-md-6 */}
          <div className="col-md-6 right-data d-none d-md-block">
            <div className="img-box">
              <img src="/img/new-home/download-app.png" alt="download" />
            </div>
          </div>
        </div>
      </div>

      <div className="dash-ins-info">
        {placement === "parent" ? (
          <>
            <h3 className="mgb10">Get Ready for the Games!</h3>
            <IndividualRegistrationInstructions />
          </>
        ) : placement === "school" ? (
          <>
            <div className="-mb-4">
              <h3>Other Resources</h3>
              <ul className="bullet-list-data mgt0">
                <li>
                  Frequently Asked Questions{" "}
                  <a
                    target="_blank"
                    href="/faqLanding"
                    className="btn-link font-semibold"
                  >
                    FAQs
                  </a>
                  .
                </li>
                <li>
                  Share the unique access code with your students through email
                  or school announcements.
                </li>
                <li>Encourage student participation in the Olympiad.</li>
              </ul>
            </div>
            <SchoolRegistrationInstructions />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default DashboardInstructions;

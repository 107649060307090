import { initializeApp } from "firebase/app";
import {
  getAuth,
  // GoogleAuthProvider
} from "firebase/auth";
import { getFirestore, collection } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

import { deployment } from "./envConfig";

const firebaseConfig =
  deployment === "production"
    ? {
        apiKey: "AIzaSyDowG3s9pyvi8UxI3LiJghRQ1KrckXEdFI",
        authDomain: "practido-ai-assessment.firebaseapp.com",
        projectId: "practido-ai-assessment",
        storageBucket: "practido-ai-assessment.appspot.com",
        messagingSenderId: "620694934859",
        appId: "1:620694934859:web:79d2b7808d229f459c84ce",
        measurementId: "G-MEYY6ZYBCX",
      }
    : {
        apiKey: "AIzaSyDvL_xP6uAiBKhsWen7WApQC1qVdkGC4ow",
        authDomain: "naskay-test.firebaseapp.com",
        projectId: "naskay-test",
        storageBucket: "naskay-test.appspot.com",
        messagingSenderId: "221453283973",
        appId: "1:221453283973:web:0aa2efde3065e1dc89afec",
        measurementId: "G-H98EEM5XTJ",
      };

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
// export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

export const schoolCollectionRef = collection(db, "schools");
export const parentsCollectionRef = collection(db, "parents");
export const participantsCollectionRef = collection(db, "participants");
export const paymentCollectionRef = collection(db, "payments");
export const questionsCollectionRef = collection(db, "questions");

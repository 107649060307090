import React, { useEffect, useState } from "react";
import { Button } from "../../../components";
import { Link, useNavigate } from "react-router-dom";
import {
  auth,
  parentsCollectionRef,
  participantsCollectionRef,
  schoolCollectionRef,
} from "../../../config/firebaseConfig";
import { doc, getDoc, onSnapshot, query, where } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import StudentCard from "./StudentCard";
import SchoolLogo from "../school/SchoolLogo";
import { IoWarningOutline } from "react-icons/io5";
import { sendVerificationEmail } from "../dashboardFunctions";
import { LuLoader2 } from "react-icons/lu";
import DashboardInstructions from "./DashboardIns";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFContent from "../school/PDFContent";

const ParentDashboard = () => {
  const navigate = useNavigate();
  const [emailVerified, setEmailVerified] = useState(null);
  const [parentsDetails, setParentsDetails] = useState(null);
  const [students, setStudents] = useState(null);
  const [schoolDetails, setSchoolDetails] = useState(null);
  const [loading, setLoading] = useState(null);
  const [verificationEmailSent, setVerificationEmailSent] = useState(null);

  useEffect(() => {
    const fetchParentDetails = async () => {
      const uid = auth.currentUser.uid;
      const parentQuery = query(parentsCollectionRef, where("uid", "==", uid));
      const participantsQuery = query(
        participantsCollectionRef,
        where("parentUid", "==", uid)
      );

      try {
        onSnapshot(parentQuery, (snap) => {
          if (snap.empty) return navigate("/login");
          let data = snap.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          });
          setParentsDetails(data[0]);
        });
      } catch (error) {
        console.log(error);
      }

      try {
        onSnapshot(participantsQuery, (snap) => {
          let data = snap.docs.map((doc) => {
            return { ...doc.data(), id: doc.id };
          });
          setStudents(data);
        });
      } catch (error) {
        console.log(error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchParentDetails();
        setEmailVerified(user?.emailVerified);
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe(); // Cleanup when component unmounts
  }, [navigate]);

  useEffect(() => {
    const schoolId = parentsDetails?.schoolId;
    const fetchSchooolDetails = async () => {
      const schoolData = await getDoc(doc(schoolCollectionRef, schoolId));
      setSchoolDetails({
        schoolId: schoolData.id,
        ...schoolData.data(),
      });
    };

    schoolId && fetchSchooolDetails();
  }, [parentsDetails]);

  return (
    <div className="container py-12 space-y-6">
      {!emailVerified && (
        <div className="bg-[#FFDBD2] text-[#FF0707] p-3 flex gap-2 items-center justify-center rounded-xl">
          <IoWarningOutline className="w-5 h-5 " />
          <span className="text-base">
            Please verify your email address to unlock more features and
            services. We've sent a verification link to your registered email.
          </span>
          {verificationEmailSent ? (
            <span className="font-bold">✓ Sent</span>
          ) : (
            <button
              className="font-extrabold hover:underline flex items-center gap-2"
              onClick={() =>
                sendVerificationEmail({
                  actionUrl: "parentDashboard",
                  setLoading: setLoading,
                  setVerificationEmailSent: setVerificationEmailSent,
                })
              }
            >
              Resend Link
              {loading?.resend && (
                <span>
                  <LuLoader2 className="animate-spin" />
                </span>
              )}
            </button>
          )}
        </div>
      )}
      <div className="parents-dashbaord-content space-y-4">
        {parentsDetails?.schoolId && (
          <div>
            <SchoolLogo schoolDetails={schoolDetails} />
          </div>
        )}
        <h3>PRACTIDO Online Olympiad 2024 Participant Information</h3>
        <div className="flex gap-4 flex-wrap">
          {students?.map((student) => (
            <StudentCard student={student} key={student.id} />
          ))}
        </div>
        <div>
          <Button
            text={`Add ${students?.length ? "More" : ""} Participants`}
            version="secondary"
            isDisabled={!emailVerified}
            handleClick={() => {
              navigate("/onboarding/step1");
            }}
          />
          {!emailVerified && (
            <p className="text-sm text-[#FF0707] p-2">
              Verify your email id to add participants.
            </p>
          )}
        </div>
      </div>
      <div className="dash-blue-card gap-4">
        <div className="col-md-6 w-full bg-[#DBF2F7] rounded-xl p-4 flex flex-col gap-1 justify-center">
          <h3 className="text-lg font-bold">Mark Your Calendars!</h3>
          <p className="font-medium">
            The Practido Online Olympiad exam is happening on{" "}
            <strong>[Date of Exam]</strong>.
          </p>
          <Link
            to="/examlanding"
            // target="_blank"
            className="bg-white p-2 px-4 rounded-full font-semibold w-fit hover:no-underline"
            style={{ boxShadow: "0px 3px 0px #DFDFDF" }}
          >
            Preview Exam Details
          </Link>
        </div>
        <div className="col-md-6 w-full bg-[#DBF2F7] rounded-xl p-4 flex flex-col gap-1 justify-center">
          <p className="text-base font-medium">
            Ensure a smooth Olympiad experience! Download the Information PDF
            for your child's competition details.
          </p>

          <button
            className="bg-white p-2 px-4 rounded-full font-semibold w-fit"
            style={{ boxShadow: "0px 3px 0px #DFDFDF" }}
          >
            <PDFDownloadLink
              document={<PDFContent schoolDetails={schoolDetails} />}
              fileName="Practido-Olympiad-Instructions.pdf"
            >
              {({ blob, url, loading, error }) =>
                loading ? "Loading ..." : "Click here to download the PDF"
              }
            </PDFDownloadLink>
          </button>
        </div>
      </div>

      <>
        <DashboardInstructions placement="parent" />
      </>
    </div>
  );
};

export default ParentDashboard;
